
import { Vue, Component, Prop } from "vue-property-decorator";
import DeletePopover from "@/components/DeletePopover.vue";
import * as ColumnService from "@/api/helpers/Column";
import OfferType from "@/models/OfferType";
import Column from "@/models/Column";
import { ElForm } from "element-ui/types/form";
import Notify from "@/utils/notifications";

interface FormRule {
  required?: boolean;
  type?: string;
  min?: number;
  max?: number;
  validator?: (
    rule: any,
    value: any,
    callback: (error?: string) => void
  ) => void;
  trigger?: string;
  pattern?: RegExp;
  message: string;
}

interface TextAreaSize {
  minRows: number;
  maxRows: number;
}

@Component({
  components: {
    DeletePopover,
  },
})
export default class OfferForm extends Vue {
  @Prop({ required: true }) offer!: OfferType;
  @Prop({ required: true }) offersOrders!: number[];

  textAreaSize: TextAreaSize = { minRows: 6, maxRows: Infinity };
  columns: ColumnService.Column[] = [];

  rules: { [key: string]: FormRule[] } = {
    name: [
      {
        required: true,
        message: "Este campo es requerido",
        trigger: "blur",
      },
    ],
    customParams: [
      {
        required: false,
        message: "",
        trigger: "blur",
        validator: (rule, value, callback) => {
          try {
            if (!value) return callback();

            let customParams;
            if (typeof value === "string") {
              customParams = JSON.parse(value);
            } else if (typeof value === "object") {
              customParams = value;
            } else {
              return callback("El formato JSON es inválido.");
            }

            let orderParam: number | undefined;

            if (Array.isArray(customParams)) {
              const paramWithOrder = customParams.find(
                param =>
                  typeof param === "object" &&
                  param !== null &&
                  "order" in param
              );
              if (paramWithOrder) {
                orderParam = paramWithOrder.order;
              }
            } else if (customParams && typeof customParams === "object") {
              orderParam = customParams.order;
            }

            if (orderParam !== undefined) {
              if (!Number.isInteger(orderParam)) {
                return callback("El campo 'order' debe ser un número entero.");
              }
              if (this.offersOrders.includes(orderParam)) {
                return callback(
                  `El valor 'order' debe ser único entre los tipos de ofertas.`
                );
              }
            }

            callback();
          } catch (error) {
            return callback("El formato del JSON es incorrecto.");
          }
        },
      },
    ],
  };

  isColumnSelected(id: number): boolean {
    return this.offer.columns.some(column => column.id === id);
  }

  isColumnCreated(index: number): boolean {
    return "offerTypeColumnId" in this.offer.columns[index];
  }

  addColumn() {
    this.offer.columns.push(new Column());
  }

  deleteColumn(value: number) {
    const option = this.offer.columns.find(
      column => column.offerTypeColumnId === value
    );
    if (option) {
      this.offer.columns = this.offer.columns.filter(
        column => column.offerTypeColumnId !== option.offerTypeColumnId
      );
      this.$emit("deleteColumn", option.offerTypeColumnId);
    } else {
      this.offer.columns.splice(value, 1);
    }

    if (this.offer.columns.length === 0) {
      this.addColumn();
    }
  }

  formatParams() {
    try {
      this.offer.customParams = JSON.stringify(
        JSON.parse(this.offer.customParams),
        null,
        2
      );
    } catch (e) {
      //
    }
  }

  findColumns() {
    ColumnService.find()
      .then(res => {
        this.columns = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  validateForm(): Promise<boolean> {
    return (this.$refs.offerForm as ElForm).validate();
  }

  created() {
    this.formatParams();
    this.findColumns();
  }
}
