
import { Vue, Component, Prop, Watch, Ref } from "vue-property-decorator";
import OfferForm from "./OfferForm.vue";
import * as SystemServices from "@/api/helpers/System";
import * as OfferTypeServices from "@/api/helpers/OfferType";
import * as ColumnService from "@/api/helpers/Column";
import { Count } from "@/api/Gondor";
import OfferType from "@/models/OfferType";
import Notify from "@/utils/notifications";
import { AxiosResponse } from "axios";

interface System {
  id: number;
  name: string;
  url: string;
  keyId: string;
  active: boolean;
  showImprovement: boolean;
  createdAt: string;
  updatedAt: string;
  countryId: number;
}

@Component({
  components: {
    OfferForm,
  },
})
export default class OfferDrawer extends Vue {
  @Prop({ required: true }) show!: boolean;
  @Prop({ required: true }) system!: System;
  @Ref() readonly formRef!: OfferForm;

  systemOffers: Count<SystemServices.SystemOffer> = { count: 0, rows: [] };
  loading = false;
  loadingForm = false;
  create = true;
  offersOrders: number[] = [];

  offer = new OfferType();
  showDialog = false;

  columnsToDelete: (() => Promise<AxiosResponse<any>>)[] = [];

  draggedIndex: number | null = null;

  createOffer() {
    this.offer = new OfferType();
    this.handleDialog(true);
    this.create = true;
  }

  editOffer(offerId: number) {
    const offerEdit = this.systemOffers.rows.find(
      offer => offer.id === offerId
    );
    if (offerEdit) {
      this.offer = {
        id: offerEdit.id,
        name: offerEdit.name,
        createdAt: offerEdit.createdAt,
        customParams: offerEdit.customParams,
        description: offerEdit.description as string | undefined,
        columns: offerEdit.columns,
        updatedAt: offerEdit.updatedAt,
      };

      const offerOrder = this.getOfferOrder(this.offer);

      this.offersOrders = this.systemOffers.rows
        .map(offer => this.getOfferOrder(offer))
        .filter(order => Number.isInteger(order) && order != offerOrder);
      this.handleDialog(true);
      this.create = false;
    }
  }

  newOfferType() {
    this.loadingForm = true;
    this.formRef
      .validateForm()
      .then(() => {
        const { name, customParams, description } = this.offer;
        OfferTypeServices.create({ name, customParams, description })
          .then(res => {
            const offerTypeId = res.data.data.id;
            const validColumns = this.offer.columns.filter(
              column => column.id !== undefined
            );
            const columnsPromise = validColumns.map(column => {
              return OfferTypeServices.addColumn({
                columnId: column.id as number,
                offerTypeId: offerTypeId,
              });
            });

            //Add promise to create relation OfferType - System
            columnsPromise.push(
              OfferTypeServices.addSystem({
                offerTypeId,
                systemId: this.system.id,
              })
            );

            Promise.all(columnsPromise)
              .then(() => {
                Notify.successful(`Oferta creada exitosamente.`);
              })
              .catch(error => {
                Notify.gebServerError(error);
              })
              .finally(() => {
                this.loadingForm = false;
                this.handleDialog(false);
                this.findOffers(this.system.id);
              });
          })
          .catch(error => {
            Notify.gebServerError(error);
            this.loadingForm = false;
          });
      })
      .catch(() => {
        this.loadingForm = false;
      });
  }

  updateOfferType(offerType?: any) {
    this.loadingForm = true;
    if (offerType && offerType.id) {
      const { name, customParams, description, id } = offerType;
      OfferTypeServices.update(id, {
        name,
        customParams,
        description,
      })
        .then(() => {
          this.loadingForm = false;
        })
        .catch(error => {
          Notify.gebServerError(error);
          this.loadingForm = false;
          this.columnsToDelete = [];
        });
    } else {
      this.formRef
        .validateForm()
        .then(() => {
          const { name, customParams, description, id } = this.offer;
          if (id !== undefined) {
            const promiseArray = this.columnsToDelete.map(deleteFn =>
              deleteFn()
            );
            const columnsToCreate = this.offer.columns.filter(
              column =>
                column.offerTypeColumnId === undefined &&
                column.id !== undefined
            );

            const columnsPromise = columnsToCreate.map(column => {
              return OfferTypeServices.addColumn({
                columnId: column.id as number,
                offerTypeId: id,
              });
            });

            OfferTypeServices.update(id, {
              name,
              customParams,
              description,
            })
              .then(() => {
                Promise.all([promiseArray, columnsPromise])
                  .then(() => {
                    Notify.successful(`Oferta actualizada exitosamente.`);
                  })
                  .catch(error => {
                    Notify.gebServerError(error);
                  })
                  .finally(() => {
                    this.loadingForm = false;
                    this.handleDialog(false);
                    this.findOffers(this.system.id);
                    this.columnsToDelete = [];
                  });
              })
              .catch(error => {
                Notify.gebServerError(error);
                this.loadingForm = false;
                this.columnsToDelete = [];
              });
          }
        })
        .catch(() => {
          this.loadingForm = false;
        });
    }
  }

  deleteColumn(columnId: number) {
    if (!this.create) {
      this.columnsToDelete.push(() => OfferTypeServices.deleteColumn(columnId));
    }
  }

  handleDialog(status: boolean) {
    this.showDialog = status;
  }

  columnHasId(column: ColumnService.Column): boolean {
    return "id" in column;
  }

  closeDrawer() {
    this.$emit("closeDrawer", true);
  }

  findOffers(systemId: number) {
    this.loading = true;
    SystemServices.offerType(systemId)
      .then(res => {
        const orderOffers = res.data.data.rows.sort((a, b) => {
          const orderA = this.getOfferOrder(a);
          const orderB = this.getOfferOrder(b);

          return orderA - orderB;
        });
        this.systemOffers.rows = orderOffers;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getOfferOrder(offer) {
    const customParams = offer.customParams
      ? JSON.parse(offer.customParams)
      : [];
    let order;
    if (Array.isArray(customParams)) {
      order =
        customParams.find(param =>
          Object.prototype.hasOwnProperty.call(param, "order")
        )?.order ?? offer.name;
    } else if (customParams && typeof customParams === "object") {
      order = "order" in customParams ? customParams.order : offer.name;
    } else {
      order = offer.name;
    }
    return order;
  }

  dragStart(index) {
    this.draggedIndex = index;
  }

  updateCustomParams(itemsIds) {
    itemsIds.forEach((id, index) => {
      const offer = this.systemOffers.rows.find(row => row.id === id);
      if (!offer) return;

      let orderParam;

      if (Array.isArray(offer.customParams)) {
        orderParam = offer.customParams.find(param =>
          Object.prototype.hasOwnProperty.call(param, "order")
        );
      } else if (offer.customParams && typeof offer.customParams === "object") {
        orderParam =
          "order" in offer.customParams
            ? { order: offer.customParams.order }
            : { order: offer.name };
      } else {
        orderParam = { order: offer.name };
      }

      const existingParamOrder = Object.prototype.hasOwnProperty.call(
        orderParam,
        "order"
      )
        ? true
        : false;
      let updated = false;

      if (existingParamOrder) {
        if (orderParam.order !== index) {
          orderParam.order = index;
          updated = true;
        }
      }

      if (updated) {
        const parsedParams = JSON.parse(offer.customParams || "");
        if (!Array.isArray(parsedParams)) {
          parsedParams.order = index;
        } else {
          if (parsedParams[0]) {
            parsedParams[0].order = index;
          } else {
            parsedParams.push({ order: index });
          }
        }
        offer.customParams = JSON.stringify(parsedParams);
        this.updateOfferType(offer);
      }
    });
  }

  drop(targetIndex: number) {
    if (this.draggedIndex !== null && this.draggedIndex !== targetIndex) {
      const items = [...this.systemOffers.rows];
      const movedItem = items.splice(this.draggedIndex, 1)[0];
      items.splice(targetIndex, 0, movedItem);

      this.systemOffers.rows = items;
      const itemsIds = items.map(item => item.id);

      this.updateCustomParams(itemsIds);
    }
    this.draggedIndex = null;
  }

  @Watch("system.id", { immediate: true })
  onSystemChange(newSystemId: number) {
    if (newSystemId) {
      this.findOffers(newSystemId);
    }
  }

  get titleDrawer() {
    return `Ofertas ${this.system.name}`;
  }

  get titleDialog() {
    return `${this.create ? "Crear" : "Actualizar"} oferta`;
  }
}
